import React, {useRef, useState} from 'react';
import Layout from '../layouts/es.jsx';
import {Link} from "gatsby";
import Slider from "react-slick";
import {SlickCustomDots} from "../components/shared";

const NewsSingleEs = ({pageContext}) => {
  const {id, data } = pageContext;
  const sliderRef = useRef();
  const modalWantRef = useRef();
  const [sliderCurrent, setSliderCurrent] = useState(1);
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    dots: true,
    appendDots: dots => {
      return (<SlickCustomDots dots={dots} gallery={data.fieldPosts.gallery} featured={data.featuredImage.node} slider={sliderRef}/>);
    },
    beforeChange: (current, next) => setSliderCurrent(next + 1),
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          appendDots: dots => <ul>{dots}</ul>,
        }
      }
    ]
  };

  return (
    <Layout pageId="news-details" scrollTop={false}>
      <div className="container">
        <div className="to-back">
          <Link className="btn btn--back" to="/">
            <i className="icon icon-arrow-left--gray"></i>
            Regresar
          </Link>
        </div>

        <div className="row detail-row">
          <div className="col-lg-4 information">
            <div className="content">
              {data.fieldPosts.logo ? (
                <img src={data.fieldPosts.logo.sourceUrl} alt="Article logo img" className="article-logo" />
              ) : (<></>)}
              <h1>{data.title}</h1>
              <p className="description" dangerouslySetInnerHTML={{__html: data.content}}>
              </p>
              { (data.fieldPosts.cta) ? (
                <div className="cta-container">
                  <Link className="link" to={data.fieldPosts.cta.url} target="_blank" rel="noreferrer noopener nofollow">
                    {data.fieldPosts.cta.text}
                    <i className="icon icon-arrow-right--gray"></i>
                  </Link>
                </div>
              ) : (<></>) }
            </div>
          </div>

          <div className="col-lg-8 image">
              { data.fieldPosts.gallery ?
                (
                  <div className="custom_slick_slider">
                    <Slider {...settings} ref={sliderRef}>
                      {data.fieldPosts.gallery.map((v, index) => (
                        <picture key={index}>
                          <img src={v.sourceUrl} alt={`Gallery Image (${index}) of ${data.title}`} />
                        </picture>
                      ))}
                    </Slider>
                  </div>
                ) :
                (
                  <img src={data.featuredImage.node.sourceUrl} alt="Imagen 2" className="just-featured" />
                )
              }
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewsSingleEs;
